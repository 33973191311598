


















import validator from 'validator';
import { Form } from '../components/admin';
import { NotificationMixin } from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

@Component({
  name: 'UpdatePassword',
  components: {
    Form,
  },
})
export default class UpdatePassword extends Mixins(NotificationMixin) {
  model = {};
  schema = [
    {
      key: 'password',
      type: 'password',
      hint: {
        content: `
                    <span>Must contain:</span>
                    <ul>
                        <li>At least 8 characters</li>
                        <li>At least 1 uppercase character</li>
                        <li>At least 1 lowercase character</li>
                        <li>At least 1 number</li>
                    </ul>
                    `,
        html: true,
      },
      options: {
        labelWidth: '200px',
      },
      rules: [
        {
          required: true,
          trigger: 'blur',
        },
        {
          validator: (rule, value, callback) => {
            const match = validator.matches(
              value,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,}$/
            );
            if (!match) {
              callback(new Error('Password does not meet criteria'));

              return;
            }
            callback();
          },
        },
      ],
    },
    {
      key: 'confirmPassword',
      type: 'password',
      hint: 'Password must match',
      options: {
        labelWidth: '200px',
      },
      rules: [
        {
          required: true,
          message: 'Password confirmation is required',
          trigger: 'blur',
        },
      ],
    },
  ];

  readonly $refs!: {
    form: Form;
  };

  onSubmit(data) {
    return this.$store
      .dispatch('auth/updatePassword', { data })
      .then(() => {
        this.model = {};
        this.$refs.form.resetForm();
        this.notifySuccess('Password updated');
        this.$router.push({ name: 'dashboard' });
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to update password.';
        this.notifyError(errMessage);
      });
  }
}
